import { http, httpNoAuth } from "..";
import {
  deleteBrandPayload,
  getBrandsFilter,
  restoreBrandPayload,
} from "./brands-interface";

/**
 * Ger brands
 * @param filter object
 * @returns http response
 */
export const getBrands = async (filter?: getBrandsFilter) => {
  return await httpNoAuth.get("/brands", {
    params: {
      ...filter,
    },
  });
};

/**
 * Create brand
 * @param data object
 * @returns http response
 */
export const createBrand = async (data: any) => {
  return await http.post("/brands", data);
};

/**
 * Edit brand
 * @param data object
 * @returns http response
 */
export const editBrand = async (data: any, brandId: string) => {
  return await http.patch(`/brands/${brandId}`, data);
};

/**
 * Delete brand
 * @param data object
 * @returns http response
 */
export const deleteBrand = async (data: deleteBrandPayload) => {
  return await http.delete("/brands/delete-brand", {
    params: {
      ...data,
    },
  });
};

/**
 * Restore brand
 * @param data object
 * @returns http response
 */
export const restoreBrand = async (data: restoreBrandPayload) => {
  return await http.post("/brands/restore-brand", data);
};
