const development = false;

const devConfig = {
  // BASE_API_URL: "http://localhost:8001/api/v1",
  BASE_API_URL: "https://api.desibazaarapp.com/api/v1",
};

const prodConfig = {
  BASE_API_URL: "https://api.desibazaarapp.com/api/v1",
};

export const appDetails = development ? devConfig : prodConfig;
