import React from "react";

const Homepage = () => {
  return (
    <div>
      <div className="flex justify-center">
        <img src="/img/logo/logo.png" alt="logo" className="w-20" />
      </div>

			<div className="flex justify-center">
        <h1 className="text-3xl font-medium">Desi Bazar</h1>
      </div>
    </div>
  );
};

export default Homepage;
