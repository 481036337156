import { useState } from "react";
import { getBrandsFilter } from "../../api/brands/brands-interface";
import { getCategories } from "../../api/categories/categories";

export interface category {
  _id: string;
  name: string;
  imageUrl: string;
	active: boolean;
}

const useCategories = () => {
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [categories, setCategories] = useState<category[]>([]);
  const [totalDocs, setTotalDocs] = useState(0);

  const fetchCategories = async (filter?: getBrandsFilter) => {
    try {
      setLoadingCategories(true);
      const res = await getCategories(filter);
      const { docs } = res.data?.data;
      setCategories(docs);
      setLoadingCategories(false);
      setTotalDocs(totalDocs);
    } catch (error) {
      setLoadingCategories(false);
      console.log(error);
    }
  };

  return { loadingCategories, categories, fetchCategories, totalDocs };
};

export default useCategories;
