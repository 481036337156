import React from "react";
import { FileDoneOutlined } from "@ant-design/icons";

const StripeAccountLinkingSuccess = () => {
  return (
    <div>
      <div className="flex justify-center">
        <img src="/img/logo/logo.png" alt="logo" className="w-40 h-40" />
      </div>

      <div className="flex justify-center">
        <h1 className="text-2xl font-medium text-center">
          Onboarding details submitted successfully and it's under review.
        </h1>
      </div>

      <div className="flex flex-col items-center justify-center w-11/12 mx-auto mt-10 md:3/12 lg:w-3/12">
        <FileDoneOutlined className="text-green-600 text-[10rem]" />
        <h1 className="mt-10 text-2xl font-medium text-center">
          Go back to app
        </h1>
      </div>
    </div>
  );
};

export default StripeAccountLinkingSuccess;
