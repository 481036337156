import { Card } from "antd";
import React, { useEffect, useState } from "react";
import {
  ApartmentOutlined,
  ShoppingCartOutlined,
  BoldOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { getAppMetrics } from "../../../../api/base/base";
import { useNavigate } from "react-router-dom";

const Dashboardhome = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [metrics, setMetrics] = useState<{
    totalProducts?: number;
    totalBrands?: number;
    totalCategories?: number;
    totalQuestions?: number;
  }>({});

  const runFetchMetrics = async () => {
    try {
      setLoading(true);
      const res = await getAppMetrics();
      const { data } = res.data;
      setMetrics(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    runFetchMetrics();
  }, []);

  return (
    <div className="mt-4">
      <div className="grid items-center justify-between gap-4 md:grid-cols-4 sm:grid-cols-2 xs:grid-cols-2 max-xs:grid-cols-1">
        <Card
          hoverable
          className="bg-white shadow-lg rounded-md dashboard-card-home h-[10rem] relative p-4 mb-4"
          loading={loading}
          onClick={() => {
            navigate("/admin/dashboard/products");
          }}
        >
          <div>
            <p className="text-[16px] opacity-[0.5]">Total Products</p>
            <p className="text-[20px] font-bold">
              {metrics?.totalProducts ?? 0}
            </p>
          </div>

          <div className="absolute bottom-[0.5rem] right-[0.5rem]">
            <ShoppingCartOutlined className="text-[60px] opacity-[0.3]" />
          </div>
        </Card>

        <Card
          hoverable
          className="bg-white shadow-lg rounded-md dashboard-card-home h-[10rem] relative p-4 mb-4"
          loading={loading}
          onClick={() => {
            navigate("/admin/dashboard/brands");
          }}
        >
          <div>
            <p className="text-[16px] opacity-[0.5]">Total Brands</p>
            <p className="text-[20px] font-bold">{metrics?.totalBrands ?? 0}</p>
          </div>

          <div className="absolute bottom-[0.5rem] right-[0.5rem]">
            <BoldOutlined className="text-[60px] opacity-[0.3]" />
          </div>
        </Card>

        <Card
          hoverable
          className="bg-white shadow-lg rounded-md dashboard-card-home h-[10rem] relative p-4 mb-4"
          loading={loading}
          onClick={() => {
            navigate("/admin/dashboard/categories");
          }}
        >
          <div>
            <p className="text-[16px] opacity-[0.5]">Total Categories</p>
            <p className="text-[20px] font-bold">
              {metrics?.totalCategories ?? 0}
            </p>
          </div>

          <div className="absolute bottom-[0.5rem] right-[0.5rem]">
            <ApartmentOutlined className="text-[60px] opacity-[0.3]" />
          </div>
        </Card>

        <Card
          hoverable
          className="bg-white shadow-lg rounded-md dashboard-card-home h-[10rem] relative p-4 mb-4"
          loading={loading}
          onClick={() => {
            navigate("/admin/dashboard/questions");
          }}
        >
          <div>
            <p className="text-[16px] opacity-[0.5]">Total Questions </p>
            <p className="text-[20px] font-bold">
              {metrics?.totalQuestions ?? 0}
            </p>
          </div>

          <div className="absolute bottom-[0.5rem] right-[0.5rem]">
            <QuestionCircleOutlined className="text-[60px] opacity-[0.3]" />
          </div>
        </Card>

        {/* <Card
          hoverable
          className="bg-white shadow-lg w-[24%] rounded-md dashboard-card-home h-[10rem] relative p-4 mb-4"
        >
          <div>
            <p className="text-[16px] opacity-[0.5]">Offers </p>
            <p className="text-[20px] font-bold">100</p>
          </div>

          <div className="absolute bottom-[0.5rem] right-[0.5rem]">
            <AlertOutlined className="text-[60px] opacity-[0.3]" />
          </div>
        </Card> */}
      </div>
    </div>
  );
};

export default Dashboardhome;
