import { useState } from "react";
import { getBrandsFilter } from "../../api/brands/brands-interface";
import { getBrands } from "../../api/brands/brands";

export interface brand {
  _id: string;
  name?: string;
  imageUrl?: string;
  nameIsAbbreviation?: boolean;
	active: boolean;
}

const useBrands = () => {
  const [loadingBrands, setLoadingBrands] = useState(false);
  const [brands, setBrands] = useState<brand[]>([]);
	const [totalDocs, setTotalDocs] = useState(0);

  const fetchBrands = async (filter?: getBrandsFilter) => {
    try {
      setLoadingBrands(true);
      const res = await getBrands(filter);
			const { docs, totalDocs } = res.data?.data;
      setBrands(docs);
      setLoadingBrands(false);
			setTotalDocs(totalDocs);
    } catch (error) {
      setLoadingBrands(false);
      console.log(error);
    }
  };

  return { loadingBrands, brands, fetchBrands, totalDocs };
};

export default useBrands;
