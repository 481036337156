import React, { useEffect, useState } from "react";
import {
  NotificationFilled,
  UserOutlined,
  DownOutlined,
  ShoppingCartOutlined,
  HomeOutlined,
  BoldOutlined,
  ApartmentOutlined,
  QuestionCircleOutlined,
  AlertOutlined,
  LogoutOutlined,
  MenuOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { motion } from "framer-motion";
import { Avatar, Badge, Dropdown, MenuProps, Timeline } from "antd";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../../store/userSlice/userSlice";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { TimeLineItemProps } from "antd/es/timeline/TimelineItem";
import useMediaQuery from "../../../utils/useMediaQueries";

const Admindashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [toggleMenu, setToggleMenu] = useState<boolean>(false);

  const isAboveSmallScreens = useMediaQuery("(min-width: 768px)");

  useEffect(() => {
    if (isAboveSmallScreens) setToggleMenu(false);
  }, [isAboveSmallScreens]);

  const logCurrentUserOut = () => {
    dispatch(logoutUser());
    navigate("/admin");
  };

  const actualNavLinks = [
    {
      title: "Dashboard",
      route: "/admin/dashboard",
      icon: <HomeOutlined className="bg-transparent" />,
      show: true,
    },
    {
      title: "Brands",
      route: "/admin/dashboard/brands",
      icon: <BoldOutlined className="bg-transparent" />,
      show: true,
    },
    {
      title: "Products",
      route: "/admin/dashboard/products",
      icon: <ShoppingCartOutlined className="bg-transparent " />,
      show: true,
    },
    {
      title: "Create Product",
      route: "/admin/dashboard/products/create",
      icon: <ShoppingCartOutlined className="bg-transparent" />,
      show: false,
    },
    {
      title: "Edit Product",
      route: "/admin/dashboard/products/edit",
      icon: <ShoppingCartOutlined className="bg-transparent" />,
      show: false,
    },
    {
      title: "Categories",
      route: "/admin/dashboard/categories",
      icon: <ApartmentOutlined className="bg-transparent " />,
      show: true,
    },
    {
      title: "Questions",
      route: "/admin/dashboard/questions",
      icon: <QuestionCircleOutlined className="bg-transparent " />,
      show: true,
    },
    {
      title: "Offers",
      route: "/admin/dashboard/offers",
      icon: <AlertOutlined className="bg-transparent " />,
      show: true,
    },
    {
      title: "Logout",
      route: "#",
      icon: <LogoutOutlined className="bg-transparent " />,
      show: true,
      clickAction: () => {
        logCurrentUserOut();
      },
    },
  ];

  const constructAppRoute = () => {
    const value = actualNavLinks.filter((item) => item?.route === pathname);
    return value?.[0];
  };

  const constructNav = (): TimeLineItemProps[] => {
    const value = actualNavLinks
      .filter((item) => item.show)
      .map((item) => {
        return {
          dot: item?.icon,
          children: (
            <p
              className={`cursor-pointer nav-item ${
                item?.route === pathname ? "active" : ""
              }`}
              onClick={() => {
								if(item?.clickAction) {
									item?.clickAction?.()
								} else {
									navigate(item?.route);
									setToggleMenu(false);
								}
              }}
            >
              {item?.title}
            </p>
          ),
        };
      });
    return value;
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <p
          className="cursor-pointer"
          onClick={() => {
            logCurrentUserOut();
          }}
        >
          Logout
        </p>
      ),
    },
  ];
  return (
    <div className="flex overflow-y-hidden bg-white">
      
        <motion.nav
          initial="hidden"
          animate={ toggleMenu ? "visible": "hidden"}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: "-100%" },
            visible: { opacity: 1, x: 0 },
          }}
          className="z-50 bg-blue-100 fixed h-screen w-[200px]"
        >
          <div className="flex items-center justify-end p-4">
            <div className="flex items-center justify-center w-8 h-8 bg-gray-500 rounded-full">
              <CloseOutlined
                className="text-lg text-appColorBlue1"
                onClick={() => setToggleMenu(toggleMenu => !toggleMenu)}
              />
            </div>
          </div>
          <div className="flex pl-4">
            <img src="/img/logo/logo.png" alt="logo" className="w-10" />
          </div>

          <div className="px-4 py-6">
            <Timeline items={constructNav()} />
          </div>
        </motion.nav>

      <div className="w-[18%] h-screen shadow-lg py-3 bg-blue-100 fixed max-sm:hidden">
        <div className="flex pl-4">
          <img src="/img/logo/logo.png" alt="logo" className="w-10" />
        </div>

        <div className="px-4 py-6">
          <Timeline items={constructNav()} />
        </div>
      </div>
 

      <div className="flex-grow w-full pb-3 max-sm:ml-4 ml-[20%] relative bg-white overflow-x-hidden overflow-y-hidden mr-4">
        <div className="absolute z-10 flex items-center justify-between w-full py-2 bg-white border-b border-grey">
          <div className="flex items-center justify-center gap-2">
          <div className="items-center justify-center hidden max-sm:flex">
        <MenuOutlined 
        className="text-2xl text-appColorBlue2" 
        onClick={() => setToggleMenu(true)}
        />
      </div>
            <p className="text-2xl font-bold">{constructAppRoute()?.title}</p>
          </div>

          <div>
            <div className="flex items-center">
              <Badge dot className="mr-4">
                <NotificationFilled />
              </Badge>

              <div>
                <Dropdown menu={{ items }}>
                  <div className="flex items-center cursor-pointer">
                    <Avatar size={24} icon={<UserOutlined />} />
                    <DownOutlined className="text-[10px] pl-2 text-blue-600" />
                  </div>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>

        <div className="h-screen max-h-screen min-h-screen pt-16 pb-20 overflow-y-scroll">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Admindashboard;
