import {http, httpNoAuth} from '..';
import { deleteCategoryPayload, getCategoriesFilter, restoreCategoryPayload } from './categories-interface';


export const getCategories = async (filter?: getCategoriesFilter) => {
  return await httpNoAuth.get('/categories', {
    params: {
      ...filter,
    },
  });
};


/**
 * Create category
 * @param data object
 * @returns http response
 */
export const createCategory = async (data: any) => {
  return await http.post("/categories", data);
};

/**
 * Edit category
 * @param data object
 * @returns http response
 */
export const editCategory = async (data: any, categoryId: string) => {
  return await http.patch(`/categories/${categoryId}`, data);
};

/**
 * Delete category
 * @param data object
 * @returns http response
 */
export const deleteCategory = async (data: deleteCategoryPayload) => {
  return await http.delete("/categories/delete-category", {
    params: {
      ...data,
    },
  });
};

/**
 * Restore category
 * @param data object
 * @returns http response
 */
export const restoreCategory = async (data: restoreCategoryPayload) => {
  return await http.post("/categories/restore-category", data);
};
