import { notification } from "antd";

/**
 * Manages notifications
 * @param  type string
 * @param  msg string
 * @param  desc string
 * @returns
 */
export const openNotificationWithIcon = (
  type: "success" | "error" | "warning",
  msg?: string,
  desc?: string | any
) => {
  switch (type) {
    case "success":
      return notification.success({
        message: msg,
        description: desc,
      });
    case "error":
      return notification.error({
        message: msg,
        description: desc,
      });
    case "warning":
      return notification.warning({
        message: msg,
        description: desc,
      });
    default:
      return notification.info({
        message: msg,
        description: desc,
      });
  }
};

/**
 * Capitalizes text in a string
 * @param text string
 * @returns string
 */
export const capitalizeString = (text: string): string | undefined => {
  return typeof text === "string" && text.length > 0
    ? `${text[0].toUpperCase()}${text.slice(1)}`
    : undefined;
};

export const generateRandomString = (length: number) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
