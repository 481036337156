import { Alert, Button, Form, Input } from "antd";
import React, { useState } from "react";
import { userSignInPayload } from "../../../api/auth/auth-interface";
import { signin } from "../../../api/auth/auth";
import { jwtDecode } from "jwt-decode";
import { useDispatch } from "react-redux";
import { loginUser } from "../../../store/userSlice/userSlice";
import { useNavigate } from "react-router-dom";
import { openNotificationWithIcon } from "../../../utils/helper";

const Adminauth = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });

  const onFinish = async (values: userSignInPayload) => {
    try {
      setError(null);
      setLoading(true);

      const res = await signin({
        email: values.email,
        password: values.password,
      });

      const { message, token } = res.data;
      const user: any = jwtDecode(token);

      dispatch(
        loginUser({
          token,
          user,
        })
      );

      setLoading(false);
      openNotificationWithIcon("success", message);
      navigate("/admin/dashboard");
    } catch (error: any) {
      setLoading(false);
      const errorMessage =
        error?.response?.data?.message || "Something went wrong";

      const resErrors = error?.response?.data?.errors || {};
      if (Object.keys(resErrors).length > 0) {
        setErrors((prevState) => ({
          ...prevState,
          ...resErrors,
        }));
      } else {
        setError(errorMessage);
      }
    }
  };

  return (
    <div className="flex flex-col justify-center flex-1 h-screen px-6 pb-12 linear-bg-one lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          className="w-auto h-16 mx-auto"
          src="/img/logo/logo.png"
          alt="Your Company"
        />
        <h2 className="mt-2 text-2xl font-bold leading-9 tracking-tight text-center text-gray-900">
          Sign in to your account
        </h2>
      </div>

      <div className="p-8 mt-5 shadow-xl max-xs:w-full xs:w-[80%] sm:w-[50%] md:w-[30%] mx-auto bg-white rounded-md">
        {error != null && (
          <Alert message={error} type="error" className="w-full mb-4" />
        )}

        <p className="mb-3 opacity-[0.5]">
          To sign in, please type in your correct login credentials.
        </p>
        <Form layout="vertical" onFinish={onFinish}>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
              },
              {
                type: "email",
                message: "Invalid Email",
              },
            ]}
            validateStatus={errors?.email.length ? "error" : undefined}
            help={errors?.email.length ? errors?.email : undefined}
          >
            <Input placeholder="Email" />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
              },
            ]}
            validateStatus={errors?.email.length ? "error" : undefined}
            help={errors?.email.length ? errors?.email : undefined}
          >
            <Input.Password placeholder="Password" type="password" />
          </Form.Item>
          <Form.Item>
            <Button loading={loading} disabled={loading} htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Adminauth;
