import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Upload,
  message,
} from "antd";
import { RcFile, UploadFile, UploadProps } from "antd/es/upload";
import { useEffect, useState } from "react";
import { appDetails } from "../../../../utils/appdetails";
import {
  capitalizeString,
  openNotificationWithIcon,
} from "../../../../utils/helper";
// import { createProductProps } from "./products-interface";
import useBrands, { brand } from "../../../../customhooks/useBrands/useBrands";
import useCategories, {
  category,
} from "../../../../customhooks/useCategories/useCategories";
import {
  createProduct,
  createProductSell,
  editProduct,
} from "../../../../api/product/product";
import useProducts, {
  product,
} from "../../../../customhooks/useProducts/useProducts";
import Createbrand from "../brands/createbrand";
import { useLocation, useNavigate } from "react-router-dom";
import useQuery from "../../../../customhooks/useQuery/useQuery";
import { createProductProps } from "./products-interface";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { updateUser } from "../../../../store/userSlice/userSlice";

const measurementListing = [
  "chest",
  "shoulder",
  "sleeve",
  "shirt",
  "trouserBottomWaist",
  "trouserBottomLength",
  "hip",
  "neckline",
];

export const productSizes = [
  {
    key: "XS",
    value: "XS",
  },
  {
    key: "S",
    value: "S",
  },
  {
    key: "S/M",
    value: "S/M",
  },
  {
    key: "M",
    value: "M",
  },
  {
    key: "M/L",
    value: "M/L",
  },
  {
    key: "L",
    value: "L",
  },
  {
    key: "XL",
    value: "XL",
  },
];

const quantites = [
  {
    key: "1",
    value: "1",
  },
  {
    key: "2",
    value: "2",
  },
  {
    key: "3",
    value: "3",
  },
  {
    key: "4",
    value: "4",
  },
  {
    key: "5",
    value: "5",
  },
  {
    key: "6",
    value: "6",
  },
  {
    key: "7",
    value: "7",
  },
  {
    key: "8",
    value: "8",
  },
  {
    key: "9",
    value: "9",
  },
  {
    key: "10",
    value: "10",
  },
];

const conditionsategories = [
  {
    key: "Brand New",
    value: "Brand New",
  },
  {
    key: "Like New",
    value: "Like New",
  },
  {
    key: "Thoroughly Worn",
    value: "Thoroughly Worn",
  },
];

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const Createproduct = ({ originatingFrom }: createProductProps) => {
  const [form] = Form.useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const query = useQuery();
  const searchProductId = query.get("product_id");
  const dispatch = useDispatch();

  const { user } = useSelector((state: RootState) => state.user);

  const { products, loadingProducts, fetchProducts } = useProducts();
  const { brands, loadingBrands, fetchBrands } = useBrands();
  const { categories, loadingCategories, fetchCategories } = useCategories();

  const [checking, setChecking] = useState(true);
  const [workingProduct, setWorkingProduct] = useState<product | null>(null);
  const [modalMode, setModalMode] = useState<"edit" | "create">("create");
  const [createBrandModal, setCreateBrandModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [fileListCoverImages, setFileListCoverImages] = useState<UploadFile[]>(
    []
  );
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [errors, setErrors] = useState({
    images: "",
    price: "",
    coverImages: "",
    buyingPrice: "",
    sellingPrice: "",
  });

  const handleCancel = () => setPreviewOpen(false);

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/heic" ||
      file.type === "image/heif" ||
      file.type === "image/heic" ||
      file.type === "image/webp";

    if (!isJpgOrPng) {
      message.error("You can only upload JPG/JPEG/PNG/HEIC/HEIF/WEBP file!");
    }

    const isLt2M = file.size / 1024 / 1024 < 9;
    if (!isLt2M) {
      message.error("Image must smaller than 9MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    setErrors((prevState) => {
      return {
        ...prevState,
        images: "",
      };
    });
  };

  const handleChangeCoverImages: UploadProps["onChange"] = ({
    fileList: newFileList,
  }) => {
    setFileListCoverImages(newFileList);
    setErrors((prevState) => {
      return {
        ...prevState,
        coverImages: "",
      };
    });
  };

  const checkUpload = () => {
    if (fileList?.length === 0 || fileListCoverImages?.length === 0) {
      return true;
    }
    const allGood =
      fileList?.some((item) => item?.status !== "done") ||
      fileListCoverImages?.some((item) => item?.status !== "done");
    return allGood;
  };

  const uploadButton = (
    <button type="button" className="">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  const constructOptions = (values: brand[] | category[]) => {
    const reshapedValues = values.map((item) => {
      return {
        value: item?._id,
        label: capitalizeString(item?.name || ""),
      };
    });

    return reshapedValues;
  };

  const validateImages = () => {
    let details = {
      value: true,
      info: "",
    };

    if (
      fileList?.length < 2 &&
      fileList.every((item) => item?.status === "done")
    ) {
      return {
        value: false,
        info: "You must submit at least 2 successfully uploaded images",
      };
    }

    return details;
  };

  const validateImagesCoverImages = () => {
    let details = {
      value: true,
      info: "",
    };

    if (
      fileList?.length < 1 &&
      fileList.every((item) => item?.status === "done")
    ) {
      return {
        value: false,
        info: "You must submit at least 1 successfully uploaded image",
      };
    }

    return details;
  };

  const onFinish = async (values: any) => {
    try {
      console.log(values);
      console.log(fileList);

      let validationComplete = true;
      let newErrors = { ...errors };

      let measurementsToSend: { name: string; measurement: string }[] = [];
      measurementListing.forEach((item) => {
        measurementsToSend.push({
          name: item,
          measurement: values[item],
        });
      });

      newErrors = {
        ...newErrors,
        images: validateImages().info,
        coverImages: validateImagesCoverImages().info,
        price:
          values?.price === undefined
            ? "Final Sell Price is required"
            : isNaN(values?.price)
            ? "Invalid amount"
            : "",
        buyingPrice:
          values?.buyingPrice === undefined
            ? "Buying Price is required"
            : isNaN(values?.buyingPrice)
            ? "Invalid amount"
            : "",
        sellingPrice:
          values?.sellingPrice === undefined
            ? "Selling Price is required"
            : isNaN(values?.sellingPrice)
            ? "Invalid amount"
            : "",
      };

      setErrors(newErrors);

      if (Object.values(newErrors).some((item) => item.trim().length !== 0)) {
        validationComplete = false;
      }

      if (validationComplete) {
        setLoading(true);

        let imagesToSend: {
          url: string;
          mediaType: string;
        }[];

        let imagesToSendCoverImages: {
          url: string;
          mediaType: string;
        }[];

        imagesToSend = fileList?.map((item) => {
          return {
            //@ts-ignore
            url: item?.mediaType ? item?.url : item?.response?.data?.secure_url,
            //@ts-ignore
            mediaType: item?.mediaType
              ? //@ts-ignore
                item?.mediaType
              : item?.response?.data?.resource_type,
          };
        });

        imagesToSendCoverImages = fileListCoverImages?.map((item) => {
          return {
            //@ts-ignore
            url: item?.mediaType ? item?.url : item?.response?.data?.secure_url,
            //@ts-ignore
            mediaType: item?.mediaType
              ? //@ts-ignore
                item?.mediaType
              : item?.response?.data?.resource_type,
          };
        });

        let data = {
          // hasOldPricing,
          // allowToBeBided,
					isSold: values?.isSold,
          isAvailable: values?.isAvailable,
          coverImages: imagesToSendCoverImages,
          images: imagesToSend,
          name: values?.name,
          description: values?.description,
          categories: [values?.categories],
          sizes: [values?.sizes],
          // brands: payload?.brand === otherBrandId ? [] : [payload?.brand],
          brands: [values?.brand],
          condition: values?.condition,
          // otherBrand: payload?.otherBrand,
          price: values?.price,
          buyingPrice: values?.buyingPrice,
          sellingPrice: values?.sellingPrice,
          // oldPrice: payload?.oldPrice,
          measurements: measurementsToSend,
          instagramHandle: values?.instagramHandle,
          // active: true,

          // active: submitType === 'draft' ? false : true,
        };

        let res;

        const userDetails = {
          firstName: values?.firstName,
          lastName: values?.lastName,
          email: values?.email,
          zipCode: values?.zipCode,
          phoneNumber: values?.phoneNumber,
          instagramHandle: values?.instagramHandle,
        };

        if (modalMode === "create") {
          if (originatingFrom === "/sell") {
            res = await createProductSell({
              ...data,
              ...userDetails,
            });
          } else {
            res = await createProduct({
              ...data,
              ...userDetails,
            });

            dispatch(
              updateUser({
                user: {
                  ...user,
                  ...userDetails,
                },
              })
            );
          }
        } else {
          res = await editProduct(
            {
              ...data,
              ...userDetails,
              createdBy: workingProduct?.createdBy?._id,
            },
            workingProduct?._id || ""
          );

          if (workingProduct?.createdBy?._id === user?._id) {
            dispatch(
              updateUser({
                user: {
                  ...user,
                  ...userDetails,
                },
              })
            );
          }
        }

        const { message } = res.data;

        openNotificationWithIcon(
          "success",
          `${modalMode === "create" ? "Create" : "Edit"} Product`,
          message
        );

        setLoading(false);

        if (originatingFrom === "/sell") {
          form.resetFields();
          setFileList([]);
          setFileListCoverImages([]);
        } else {
          navigate("/admin/dashboard/products");
        }
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);

      const errorMessage =
        error?.response?.data?.message || "Something went wrong";

      const resErrors = error?.response?.data?.errors || {};
      if (Object.keys(resErrors).length > 0) {
        setErrors((prevState) => ({
          ...prevState,
          ...resErrors,
        }));
      } else {
        message.error(errorMessage);
      }
    }
  };

  const lookThroughMeasurement = (value: string) => {
    const checkForValue = workingProduct?.measurements?.find(
      (item) => item?.name
    );
    if (checkForValue) {
      return checkForValue?.measurement;
    }
    return undefined;
  };

  const handleCancelCreateBrand = () => {
    setCreateBrandModal(false);
    fetchBrands({
      active: true,
    });
  };

  const handleCancelBrand = () => {
    setCreateBrandModal(false);
  };

  useEffect(() => {
    fetchBrands({
      active: true,
    });
    fetchCategories({
      active: true,
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (modalMode === "create" && originatingFrom !== "/sell") {
      form.setFieldsValue({
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user?.email,
        zipCode: user?.zipCode,
        phoneNumber: user?.phoneNumber,
        instagramHandle: user?.instagramHandle,
      });
    }

    if (modalMode === "edit") {
      form.setFieldsValue({
        firstName: workingProduct?.createdBy?.firstName,
        lastName: workingProduct?.createdBy?.lastName,
        email: workingProduct?.createdBy?.email,
        zipCode: workingProduct?.createdBy?.zipCode,
        phoneNumber: workingProduct?.createdBy?.phoneNumber,
        instagramHandle: workingProduct?.createdBy?.instagramHandle,

				isSold: workingProduct?.isSold,
        isAvailable: workingProduct?.isAvailable,
        name: workingProduct?.name,
        price: workingProduct?.price,
        buyingPrice: workingProduct?.buyingPrice,
        sellingPrice: workingProduct?.sellingPrice,
        brand: workingProduct?.brands
          ? workingProduct?.brands[0]?._id
          : undefined,
        categories: workingProduct?.categories
          ? workingProduct?.categories[0]?._id
          : undefined,
        description: workingProduct?.description,
        sizes: workingProduct?.sizes ? workingProduct?.sizes[0] : undefined,
        condition: workingProduct?.condition,
        quantity: workingProduct?.quantity,
        chest: lookThroughMeasurement("chest"),
        shoulder: lookThroughMeasurement("shoulder"),
        sleeve: lookThroughMeasurement("sleeve"),
        shirt: lookThroughMeasurement("shirt"),
        trouserBottomWaist: lookThroughMeasurement("trouserBottomWaist"),
        trouserBottomLength: lookThroughMeasurement("trouserBottomLength"),
        hip: lookThroughMeasurement("hip"),
        neckline: lookThroughMeasurement("neckline"),
      });

      let doctoredFiles = workingProduct?.images?.map((item) => {
        return {
          uid: item?._id,
          status: "done",
          url: item?.url,
          mediaType: item?.mediaType,
        };
      });

      let doctoredFilesCoverImages = workingProduct?.coverImages?.map(
        (item) => {
          return {
            uid: item?._id,
            status: "done",
            url: item?.url,
            mediaType: item?.mediaType,
          };
        }
      );

      //@ts-ignore
      setFileList(doctoredFiles);
      //@ts-ignore
      setFileListCoverImages(doctoredFilesCoverImages);
    }
    // eslint-disable-next-line
  }, [modalMode, workingProduct, user]);

  useEffect(() => {
    if (location?.pathname === "/admin/dashboard/products/edit") {
      if (
        searchProductId !== undefined &&
        searchProductId !== null &&
        searchProductId !== ""
      ) {
        setModalMode("edit");
        fetchProducts(
          {
            productId: searchProductId,
            ignoreActive: true,
          },
          () => {
            setChecking(false);
          }
        );
      } else {
        message.warning("Inapplicable edit route");
        navigate("/admin/dashboard/products");
      }
    }
    // eslint-disable-next-line
  }, [location?.pathname, searchProductId]);

  useEffect(() => {
    if (location?.pathname === "/admin/dashboard/products/edit") {
      if (
        searchProductId !== undefined &&
        searchProductId !== null &&
        searchProductId !== ""
      ) {
        if (!checking) {
          if (products?.length > 0) {
            setWorkingProduct(products[0]);
          } else {
            message.warning("Product not found");
            navigate("/admin/dashboard/products");
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [products, location?.pathname, checking]);

  return (
    <div
      className={`p-4 mt-4 rounded-sm ${
        originatingFrom === "/sell"
          ? "w-full"
          : "border bg-grey border-gray w-full md:w-full xl:w-[80%]"
      }`}
    >
      <Spin spinning={loadingProducts}>
        <Row gutter={[16, 8]}>
          <Col lg={6} md={24} xs={24}>
            <p className="mb-3 font-semibold border-b border-grey">
              Cover Image{" "}
            </p>
            <Upload
              beforeUpload={beforeUpload}
              maxCount={1}
              accept={".png, .jpg, .jpeg, .heic, .heif"}
              name="file"
              listType="picture-card"
              fileList={fileListCoverImages}
              onPreview={handlePreview}
              onChange={handleChangeCoverImages}
              action={`${appDetails?.BASE_API_URL}/upload`}
            >
              {fileListCoverImages?.length >= 1 ? null : uploadButton}
            </Upload>
            {errors?.coverImages.length > 0 && (
              <span className="text-red-400">{errors?.coverImages}</span>
            )}
          </Col>

          <Col lg={18} md={24} xs={24}>
            <p className="mb-3 font-semibold border-b border-grey">
              Other Product Images{" "}
              <span className="opacity-[0.3] pl-1">
                (Upload at least two images)
              </span>
            </p>
            <Upload
              beforeUpload={beforeUpload}
              maxCount={10}
              accept={".png, .jpg, .jpeg, .heic, .heif, .webp"}
              name="file"
              listType="picture-card"
              fileList={fileList}
              onPreview={handlePreview}
              onChange={handleChange}
              action={`${appDetails?.BASE_API_URL}/upload`}
              multiple
            >
              {fileList?.length >= 10 ? null : uploadButton}
            </Upload>
            {errors?.images.length > 0 && (
              <span className="text-red-400">{errors?.images}</span>
            )}
          </Col>
        </Row>

        <Form layout="vertical" form={form} onFinish={onFinish}>
          {
            <>
              <p className="mb-3 font-semibold border-b border-grey">
                User Details
              </p>
              <Row gutter={[16, 8]}>
                <Col lg={6} md={6} xs={24}>
                  <Form.Item
                    name="firstName"
                    rules={[
                      {
                        required: !(originatingFrom === "/sell"
                          ? false
                          : modalMode === "create"
                          ? false
                          : workingProduct?.createdBy?._id === user?._id &&
                            modalMode === "edit"
                          ? false
                          : true),
                      },
                    ]}
                    label="First Name"
                  >
                    <Input
                      placeholder="First Name"
                      disabled={
                        originatingFrom === "/sell"
                          ? false
                          : modalMode === "create"
                          ? false
                          : workingProduct?.createdBy?._id === user?._id &&
                            modalMode === "edit"
                          ? false
                          : true
                      }
                    />
                  </Form.Item>
                </Col>

                <Col lg={6} md={6} xs={24}>
                  <Form.Item
                    name="lastName"
                    rules={[
                      {
                        required: !(originatingFrom === "/sell"
                          ? false
                          : modalMode === "create"
                          ? false
                          : workingProduct?.createdBy?._id === user?._id &&
                            modalMode === "edit"
                          ? false
                          : true),
                      },
                    ]}
                    label="Last Name"
                  >
                    <Input
                      placeholder="Last Name"
                      disabled={
                        originatingFrom === "/sell"
                          ? false
                          : modalMode === "create"
                          ? false
                          : workingProduct?.createdBy?._id === user?._id &&
                            modalMode === "edit"
                          ? false
                          : true
                      }
                    />
                  </Form.Item>
                </Col>

                <Col lg={6} md={6} xs={24}>
                  <Form.Item
                    name="zipCode"
                    rules={[
                      {
                        required: !(originatingFrom === "/sell"
                          ? false
                          : modalMode === "create"
                          ? false
                          : workingProduct?.createdBy?._id === user?._id &&
                            modalMode === "edit"
                          ? false
                          : true),
                      },
                    ]}
                    label="Zip Code"
                  >
                    <Input
                      placeholder="Zip Code"
                      disabled={
                        originatingFrom === "/sell"
                          ? false
                          : modalMode === "create"
                          ? false
                          : workingProduct?.createdBy?._id === user?._id &&
                            modalMode === "edit"
                          ? false
                          : true
                      }
                    />
                  </Form.Item>
                </Col>

                <Col lg={6} md={6} xs={24}>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                      },
                      {
                        type: "email",
                        message: "Invalid Email",
                      },
                    ]}
                    label="Email"
                  >
                    <Input
                      placeholder="Email"
                      disabled={
                        originatingFrom === "/sell"
                          ? false
                          : modalMode === "create" && user?.email !== undefined
                          ? true
                          : modalMode === "create" && user?.email === undefined
                          ? false
                          : modalMode === "edit" &&
                            workingProduct?.createdBy?._id === user?._id
                          ? true
                          : true
                      }
                    />
                  </Form.Item>
                </Col>

                <Col lg={6} md={6} xs={24}>
                  <Form.Item
                    name="phoneNumber"
                    rules={[
                      {
                        required: !(originatingFrom === "/sell"
                          ? false
                          : modalMode === "edit" &&
                            workingProduct?.createdBy?._id === user?._id
                          ? true
                          : true),
                      },
                    ]}
                    label="Phone Number"
                  >
                    <Input
                      placeholder="+12345678900"
                      disabled={
                        originatingFrom === "/sell"
                          ? false
                          : modalMode === "edit" &&
                            workingProduct?.createdBy?._id === user?._id
                          ? true
                          : true
                      }
                    />
                  </Form.Item>
                </Col>

                <Col lg={6} md={6} xs={24}>
                  <Form.Item
                    name="instagramHandle"
                    rules={[
                      {
                        required: !(originatingFrom === "/sell"
                          ? false
                          : modalMode === "create"
                          ? false
                          : workingProduct?.createdBy?._id === user?._id &&
                            modalMode === "edit"
                          ? false
                          : true),
                      },
                    ]}
                    label="Instagram Handle"
                  >
                    <Input
                      placeholder="Instagram Handle"
                      disabled={
                        originatingFrom === "/sell"
                          ? false
                          : modalMode === "create"
                          ? false
                          : workingProduct?.createdBy?._id === user?._id &&
                            modalMode === "edit"
                          ? false
                          : true
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </>
          }

          <p className="mb-3 font-semibold border-b border-grey">
            Product Details
          </p>

          <Row gutter={[16, 8]}>
            {originatingFrom !== "/sell" && (
              <Col lg={6} md={6} xs={24}>
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  label="Product Name"
                >
                  <Input placeholder="Product Name" />
                </Form.Item>
              </Col>
            )}

            <Col lg={6} md={6} xs={24}>
              <Form.Item
                name="buyingPrice"
                rules={[
                  {
                    required: true,
                  },
                ]}
                label="Buying Price (USD)"
              >
                <Input placeholder="Price" />
              </Form.Item>
            </Col>

            <Col lg={6} md={6} xs={24}>
              <Form.Item
                name="sellingPrice"
                rules={[
                  {
                    required: true,
                  },
                ]}
                label="Selling Price (USD)"
              >
                <Input placeholder="Price" />
              </Form.Item>
            </Col>

            <Col lg={6} md={6} xs={24}>
              <Form.Item
                name="price"
                rules={[
                  {
                    required: true,
                  },
                ]}
                label="Final Sell Price (USD)"
              >
                <Input placeholder="Price" />
              </Form.Item>
            </Col>

            <Col lg={6} md={6} xs={24}>
              <Form.Item
                name="brand"
                rules={[
                  {
                    required: true,
                  },
                ]}
                label="Select Brand"
              >
                <Select
                  loading={loadingBrands}
                  placeholder="Select Brand"
                  options={constructOptions(brands)}
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider style={{ margin: "8px 0" }} />
                      <div className="pl-4 mb-3">
                        <Button
                          className="bg-appColorBlue2"
                          type="primary"
                          size="small"
                          onClick={() => {
                            setCreateBrandModal(true);
                          }}
                        >
                          Add Brand
                        </Button>
                      </div>
                    </>
                  )}
                />
              </Form.Item>
            </Col>
            <Col lg={6} md={6} xs={24}>
              <Form.Item
                name="categories"
                rules={[
                  {
                    required: true,
                  },
                ]}
                label="Select Category"
              >
                <Select
                  loading={loadingCategories}
                  placeholder="Select Category"
                  options={constructOptions(categories)}
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            name="description"
            rules={[
              {
                required: true,
              },
            ]}
            label="Description / Condition"
          >
            <Input placeholder="Description / Condition" />
          </Form.Item>

          <Row gutter={[16, 8]}>
            <Col lg={8} md={8} xs={24}>
              <Form.Item
                name="sizes"
                rules={[
                  {
                    required: true,
                  },
                ]}
                label="Size"
              >
                <Select placeholder="Select Sizes" options={productSizes} />
              </Form.Item>
            </Col>
            <Col lg={8} md={8} xs={24}>
              <Form.Item
                name="condition"
                rules={[
                  {
                    required: true,
                  },
                ]}
                label="Condition"
              >
                <Select placeholder="Condition" options={conditionsategories} />
              </Form.Item>
            </Col>
            <Col lg={8} md={8} xs={24}>
              <Form.Item
                name="quantity"
                rules={[
                  {
                    required: true,
                  },
                ]}
                label="No. of Articles"
              >
                <Select
                  placeholder="Select No. of Articles"
                  options={quantites}
                />
              </Form.Item>
            </Col>
          </Row>

          <p className="mb-3 border-b font-se mibold border-grey">
            Product Measurements (Inches)
          </p>
          <Row gutter={[8, 8]}>
            <Col lg={6} md={6} xs={24}>
              <Form.Item
                name="chest"
                rules={[
                  {
                    required: true,
                  },
                ]}
                label="Chest"
              >
                <Input placeholder="Chest" />
              </Form.Item>
            </Col>

            <Col lg={6} md={6} xs={24}>
              <Form.Item
                name="shoulder"
                rules={[
                  {
                    required: true,
                  },
                ]}
                label="Shoulder"
              >
                <Input placeholder="Shoulder" />
              </Form.Item>
            </Col>

            <Col lg={6} md={6} xs={24}>
              <Form.Item
                name="sleeve"
                rules={[
                  {
                    required: true,
                  },
                ]}
                label="Sleeve"
              >
                <Input placeholder="Sleeve" />
              </Form.Item>
            </Col>

            <Col lg={6} md={6} xs={24}>
              <Form.Item
                name="shirt"
                rules={[
                  {
                    required: true,
                  },
                ]}
                label="Shirt Length"
              >
                <Input placeholder="Shirt" />
              </Form.Item>
            </Col>

            <Col lg={12} md={12} xs={24}>
              <Form.Item
                name="trouserBottomLength"
                rules={[
                  {
                    required: true,
                  },
                ]}
                label="Trouser/Bottom Length"
              >
                <Input placeholder="Trouser/Bottom Length" />
              </Form.Item>
            </Col>

            <Col lg={6} md={6} xs={24}>
              <Form.Item
                name="hip"
                rules={[
                  {
                    required: true,
                  },
                ]}
                label="Hip"
              >
                <Input placeholder="Hip" />
              </Form.Item>
            </Col>

            <Col lg={6} md={6} xs={24}>
              <Form.Item
                name="neckline"
                rules={[
                  {
                    required: true,
                  },
                ]}
                label="Neckline"
              >
                <Input placeholder="Neckline" />
              </Form.Item>
            </Col>
          </Row>

          {originatingFrom !== "/sell" && (
            <>
              <Form.Item name="isAvailable" valuePropName="checked">
                <Checkbox>Approve product to be available</Checkbox>
              </Form.Item>

              <Form.Item name="isSold" valuePropName="checked">
                <Checkbox>Product is sold?</Checkbox>
              </Form.Item>
            </>
          )}

          <Form.Item>
            <Button
              loading={loading}
              htmlType="submit"
              type="primary"
              className="bg-appColorBlue2"
              disabled={checkUpload() || loading}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Spin>

      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>

      <Modal
        title={modalMode === "create" ? "Create Brand" : "Edit Brand"}
        open={createBrandModal}
        onOk={handleCancelBrand}
        onCancel={handleCancelBrand}
        destroyOnClose
        footer={null}
        style={{ top: 20 }}
      >
        <Createbrand
          closeModal={handleCancelCreateBrand}
          modalMode="create"
          workingBrand={null}
        />
      </Modal>
    </div>
  );
};

export default Createproduct;
