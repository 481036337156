import React, { useEffect, useState } from "react";
import { createCategoryProps } from "./categories-interface";
import { Button, Form, Input, Modal, message } from "antd";
import Upload, { RcFile, UploadFile, UploadProps } from "antd/es/upload";
import { PlusOutlined } from "@ant-design/icons";
import { appDetails } from "../../../../utils/appdetails";
import {
  generateRandomString,
  openNotificationWithIcon,
} from "../../../../utils/helper";
import {
  createCategory,
  editCategory,
} from "../../../../api/categories/categories";

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const Createcategory = ({
  closeModal,
  modalMode,
  workingCategory,
}: createCategoryProps) => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [errors, setErrors] = useState({
    images: "",
    price: "",
  });

  const handleCancel = () => setPreviewOpen(false);

  // const beforeUpload = async (file: RcFile) => {
  //   let imageSizeLimit = 10;

  //   const isJpgOrPng =
  //     file.type === "image/jpeg" ||
  //     file.type === "image/png" ||
  //     file.type === "image/jpg" ||
  //     file.type === "image/heic" ||
  //     file.type === "image/heif";

  //   if (!isJpgOrPng) {
  //     message.error("You can only upload JPG/PNG file!");
  //     return Upload.LIST_IGNORE;
  //   }

  //   const isLt2M = file.size / 1024 / 1024 < imageSizeLimit;

  //   if (!isLt2M) {
  //     message.error(`Image must smaller than ${imageSizeLimit}MB!`);
  //     return Upload.LIST_IGNORE;
  //   }

  //   if (file.type === "image/heic" || file.type === "image/heif") {
  //     try {
  //       const convertedBlob: any = await heic2any({
  //         blob: file,
  //         toType: "image/png",
  //       });

  //       if (!(convertedBlob.size / 1024 / 1024 < imageSizeLimit)) {
  //         message.error(`Image must smaller than ${imageSizeLimit}MB!`);
  //         return Upload.LIST_IGNORE;
  //       }

  //       const convertedFile = new File(
  //         [convertedBlob],
  //         file.name.replace(/\.[^/.]+$/, ".png"),
  //         { type: "image/png" }
  //       );
  //       return convertedFile;
  //     } catch (error) {
  //       message.error("Error converting HEIC to PNG");
  //       return Upload.LIST_IGNORE;
  //     }
  //   }

  //   return true;
  // };

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/heic" ||
      file.type === "image/heif" ||
      file.type === "image/heic" ||
      file.type === "image/webp";

    if (!isJpgOrPng) {
      message.error("You can only upload JPG/JPEG/PNG/HEIC/HEIF/WEBP file!");
    }

    const isLt2M = file.size / 1024 / 1024 < 9;
    if (!isLt2M) {
      message.error("Image must smaller than 9MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const checkUpload = () => {
    if (fileList?.length === 0) {
      return true;
    }
    const allGood = fileList?.some((item) => item?.status !== "done");
    return allGood;
  };

  const validateImages = () => {
    let details = {
      value: true,
      info: "",
    };

    if (fileList?.length < 1) {
      return {
        value: false,
        info: "You must submit at least 1 successfully uploaded image",
      };
    }

    return details;
  };

  const onFinish = async (values: any) => {
    try {
      let validationComplete = true;
      let newErrors = { ...errors };

      newErrors = {
        ...newErrors,
        images: validateImages().info,
      };

      setErrors(newErrors);

      if (Object.values(newErrors).some((item) => item.trim().length !== 0)) {
        validationComplete = false;
      }

      if (validationComplete) {
        setLoading(true);

        let data = {
          name: values?.name,
          //@ts-ignore
          imageUrl: fileList?.[0]?.mediaType
            ? fileList?.[0]?.url
            : fileList?.[0]?.response?.data?.secure_url,
        };

        let res;

        if (modalMode === "create") {
          res = await createCategory(data);
        } else {
          res = await editCategory(data, workingCategory?._id || "");
        }

        const { message } = res.data;

        openNotificationWithIcon(
          "success",
          `${modalMode === "create" ? "Create" : "Edit"} Product`,
          message
        );

        setLoading(false);
        closeModal();
      }
    } catch (error: any) {
      setLoading(false);
      const errorMessage =
        error?.response?.data?.message || "Something went wrong";

      const resErrors = error?.response?.data?.errors || {};
      if (Object.keys(resErrors).length > 0) {
        setErrors((prevState) => ({
          ...prevState,
          ...resErrors,
        }));
      } else {
        message.error(errorMessage);
      }
    }
  };

  const uploadButton = (
    <button type="button" className="">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  useEffect(() => {
    if (modalMode === "edit") {
      console.log("workingCategory", workingCategory);

      form.setFieldsValue({
        name: workingCategory?.name,
      });

      let doctoredFiles = [
        {
          status: "done",
          url: workingCategory?.imageUrl,
          mediaType: "image",
          uid: generateRandomString(5),
        },
      ];

      //@ts-ignore
      setFileList(doctoredFiles);
    }
    // eslint-disable-next-line
  }, [modalMode, workingCategory]);

  return (
    <div>
      <Upload
        beforeUpload={beforeUpload}
        maxCount={1}
        accept={".png, .jpg, .jpeg, .heic, .heif"}
        name="file"
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        action={`${appDetails?.BASE_API_URL}/upload`}
      >
        {fileList?.length >= 1 ? null : uploadButton}
      </Upload>
      {errors?.images.length > 0 && (
        <span className="text-red-400">{errors?.images}</span>
      )}
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
          label="Category Name"
        >
          <Input placeholder="Category Name" />
        </Form.Item>

        <Form.Item>
          <Button
            loading={loading}
            htmlType="submit"
            type="primary"
            className="bg-appColorBlue2"
            disabled={checkUpload() || loading}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>

      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </div>
  );
};

export default Createcategory;
