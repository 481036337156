import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getBidsFilter } from "../../../../api/product/product-interface";
import { fetchBids, markBidAsCompleted } from "../../../../api/product/product";
import { bid, product } from "../../../../customhooks/useProducts/useProducts";
import { Button, Table, TableProps, Tag } from "antd";
import moment from "moment";
import { openNotificationWithIcon } from "../../../../utils/helper";

const Offers = () => {
  const [loading, setLoading] = useState(false);
  const [bids, setBids] = useState<bid[]>([]);
  const [totalDocs, setTotalDocs] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);
  const [currentLimit, setCurrentLimit] = useState(20);
  const [workingBid, setWorkingBid] = useState<bid | null>(null);
  const [workingBidAction, setWorkingBidAction] = useState(false);

  const requestPayload: getBidsFilter = useMemo(() => {
    return {
      page: currentPage,
      limit: currentLimit,
      adminUse: true,
    };
  }, [currentPage, currentLimit]);

  const runFetchBid = useCallback(async () => {
    console.log(requestPayload);
    try {
      setLoading(true);
      const res = await fetchBids(requestPayload);
      const { docs, totalDocs } = res.data?.data;
      setTotalDocs(totalDocs);
      setBids(docs);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
    // eslint-disable-next-line
  }, [requestPayload]);

  useEffect(() => {
    runFetchBid();
  }, [runFetchBid]);

  const runMarkBidAsCompleted = async (item: bid) => {
    try {
      setWorkingBidAction(true);
      const res = await markBidAsCompleted({
        bidId: item?._id,
      });

      const { message } = res.data;

      openNotificationWithIcon("success", "Mark Bid As Completed", message);
      setWorkingBidAction(false);
      runFetchBid();
    } catch (error: any) {
      setWorkingBidAction(false);
      const errorMessage =
        error?.response?.data?.message || "Something went wrong";
      openNotificationWithIcon("error", "Mark Bid As Completed", errorMessage);
    }
  };

  const columns: TableProps<bid>["columns"] = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text) => <span onClick={() => {}}>{text}</span>,
    },
    {
      title: "Instagram",
      dataIndex: "instagramHandle",
      key: "instagramHandle",
      render: (text) => <span onClick={() => {}}>{text}</span>,
    },
    {
      title: "Phone",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: (text) => <span onClick={() => {}}>{text}</span>,
    },
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
      render: (text: product) => (
        <span className="capitalize" onClick={() => {}}>
          {text?.name}
        </span>
      ),
    },
    {
      title: "Offered ($)",
      dataIndex: "history",
      key: "history",
      render: (
        text: {
          itemCount: number;
          price: number;
          dateSubmitted: string;
          _id: string;
        }[]
      ) => (
        <span className="capitalize" onClick={() => {}}>
          {text?.[0]?.price ?? "-"}
        </span>
      ),
    },
    {
      title: "Whatsapp Msg Delivered",
      dataIndex: "messageDetails",
      key: "messageDetails",
      render: (text: {
        from: string;
        to: string;
        provider: string;
        service: string;
        accountSid: string;
        messagingServiceSid: null;
        sid: string;
        status: string;
      }) => (
        <div>
          {text ? (
            <Tag color="green">Delivered</Tag>
          ) : (
            <Tag color="red">Not Delivered</Tag>
          )}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text: string) => (
        <div>
          {text === "completed" ? (
            <Tag color="green">Completed</Tag>
          ) : (
            <Tag color="warning">Pending</Tag>
          )}
        </div>
      ),
    },
    {
      title: "Submitted",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => <span>{moment(text).format("LL")}</span>,
    },
    {
      title: "Action",
      dataIndex: "",
      key: "",
      render: (item: bid) =>
        item?.status === "pending"  ? (
          <div className="flex items-center gap-2">
            <Button
              onClick={() => {
                setWorkingBid(item);
                runMarkBidAsCompleted(item);
              }}
              type="primary"
              className="bg-appColorBlue2"
              size="small"
              disabled={workingBidAction}
              loading={workingBid?._id === item?._id && workingBidAction}
            >
              Mark as completed
            </Button>
          </div>
        ) : '-',
        fixed: "right"
    },
  ];

  return (
    <div className="mt-4">
      <Table
        size="small"
        dataSource={bids}
        columns={columns}
        loading={loading}
        rowKey={(record) => record._id}
        pagination={{
          total: totalDocs,
          pageSize: currentLimit,
          onChange: (page, pageSize) => {
            setCurrentPage(page);
            setCurrentLimit(pageSize);
          },
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} offers`,
        }}
        scroll={{ x: "max-content" }}
      />
    </div>
  );
};

export default Offers;
