import { Button, Modal, Table, TableProps } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import useBrands, { brand } from "../../../../customhooks/useBrands/useBrands";
import { getBrandsFilter } from "../../../../api/brands/brands-interface";
import moment from "moment";
import { openNotificationWithIcon } from "../../../../utils/helper";
import { deleteBrand, restoreBrand } from "../../../../api/brands/brands";
import Createbrand from "./createbrand";

const Brands = () => {
  const { brands, loadingBrands, fetchBrands, totalDocs } = useBrands();

  const [workingBrandAction, setWorkingBrandAction] = useState(false);
  const [workingBrand, setWorkingBrand] = useState<brand | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentLimit, setCurrentLimit] = useState(20);
  const [createBrandModal, setCreateBrandModal] = useState(false);
  const [modalMode, setModalMode] = useState<"edit" | "create">("create");

  const requestPayload: getBrandsFilter = useMemo(() => {
    return {
      page: currentPage,
      limit: currentLimit,
      adminUse: true,
    };
  }, [currentPage, currentLimit]);

  const runFetchBrands = useCallback(() => {
    console.log(requestPayload);
    fetchBrands(requestPayload);
    // eslint-disable-next-line
  }, [requestPayload]);

  useEffect(() => {
    runFetchBrands();
  }, [runFetchBrands]);

  const closeModal = () => {
    setCreateBrandModal(false);
    runFetchBrands();
  };

  const handleCancel = () => {
    setCreateBrandModal(false);
  };

  const runDeleteBrand = async (item: brand) => {
    try {
      setWorkingBrandAction(true);
      const res = await deleteBrand({
        brandId: item?._id,
      });

      const { message } = res.data;

      openNotificationWithIcon("success", "Delete Brand", message);
      setWorkingBrandAction(false);
      runFetchBrands();
    } catch (error: any) {
      setWorkingBrandAction(false);
      const errorMessage =
        error?.response?.data?.message || "Something went wrong";
      openNotificationWithIcon("error", "Delete Brand", errorMessage);
    }
  };

  const runRestoreProduct = async (item: brand) => {
    try {
      setWorkingBrandAction(true);
      const res = await restoreBrand({
        brandId: item?._id,
      });

      const { message } = res.data;

      openNotificationWithIcon("success", "Restore Brand", message);
      setWorkingBrandAction(false);
      runFetchBrands();
    } catch (error: any) {
      setWorkingBrandAction(false);
      const errorMessage =
        error?.response?.data?.message || "Something went wrong";
      openNotificationWithIcon("error", "Restore Brand", errorMessage);
    }
  };

  const columns: TableProps<brand>["columns"] = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => (
        <span className="capitalize" onClick={() => {}}>
          {text}
        </span>
      ),
       fixed: 'left',
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => <span>{moment(text).format("LL")}</span>,
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      render: (item: { firstName: string; lastName: string }) => (
        <span
          className="capitalize"
          onClick={(e) => {
            e.preventDefault();
          }}
        >{`${item?.firstName} ${item?.lastName}`}</span>
      ),
    },
    {
      title: "Action",
      dataIndex: "",
      key: "",
      render: (item: brand) => (
        <div className="flex items-center gap-2">
          <Button
            type="dashed"
            size="small"
            onClick={() => {
              setWorkingBrand(item);
              setCreateBrandModal(true);
              setModalMode("edit");
            }}
          >
            Edit
          </Button>
          <Button
            onClick={() => {
              setWorkingBrand(item);
              if (item?.active) {
                runDeleteBrand(item);
              } else {
                runRestoreProduct(item);
              }
            }}
            type={item?.active ? "dashed" : "primary"}
            className={`${item?.active ? "" : "bg-appColorBlue2"}`}
            size="small"
            danger={item?.active}
            disabled={workingBrandAction}
            loading={workingBrand?._id === item?._id && workingBrandAction}
          >
            {item?.active ? "Delete" : "Restore"}
          </Button>
        </div>
      ),
       fixed: 'right',
    },
  ];

  return (
    <div className="mt-4">
      <div className="mb-4">
        <Button
          size="middle"
          type="primary"
          className="bg-appColorBlue2"
          onClick={() => {
            setModalMode("create");
            setCreateBrandModal(true);
          }}
        >
          Create Brand
        </Button>
      </div>

      <Table
        size="small"
        dataSource={brands}
        columns={columns}
        loading={loadingBrands}
        rowKey={(record) => record._id}
        pagination={{
          total: totalDocs,
          pageSize: currentLimit,
          onChange: (page, pageSize) => {
            setCurrentPage(page);
            setCurrentLimit(pageSize);
          },
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} brands`,
        }}
        scroll={{ x: "max-content" }}
      />

      <Modal
        title={modalMode === "create" ? "Create Brand" : "Edit Brand"}
        open={createBrandModal}
        onOk={handleCancel}
        onCancel={handleCancel}
        destroyOnClose
        footer={null}
        style={{ top: 20 }}
      >
        <Createbrand
          closeModal={closeModal}
          modalMode={modalMode}
          workingBrand={workingBrand}
        />
      </Modal>
    </div>
  );
};

export default Brands;
