import React, { useState } from "react";
import { Button, Form, Input } from "antd";
import { fetchStripeOnboardingLinkViaEmailPayload } from "../../api/stripe/stripe-interface";
import { openNotificationWithIcon } from "../../utils/helper";
import { fetchStripeOnboardingLinkViaEmail } from "../../api/stripe/stripe";

const StripeAccountLinkingRefresh = () => {
  const [loading, setLoading] = useState(false);

  const onFinish = async (values: fetchStripeOnboardingLinkViaEmailPayload) => {
    try {
      setLoading(true);
      const res = await fetchStripeOnboardingLinkViaEmail(values);
      const { accountLink } = res.data.data;
      // setLoading(false);
      window.location.href = accountLink?.url;
    } catch (error: any) {
      console.log(error);
      setLoading(false);

      const errorMessage =
        error?.response?.data?.message || "Something went wrong";

      openNotificationWithIcon("error", "Get onboarding link", errorMessage);
    }
  };

  return (
    <div>
      <div className="flex justify-center">
        <img src="/img/logo/logo.png" alt="logo" className="w-40 h-40" />
      </div>

      <div className="flex justify-center">
        <h1 className="text-2xl font-medium text-center">
          Get onboarding link
        </h1>
      </div>

      <div className="flex justify-center w-11/12 mx-auto mt-10 md:3/12 lg:w-3/12">
        <Form layout="vertical" className="w-full" onFinish={onFinish}>
          <Form.Item
            name="email"
            label="E-mail"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your E-mail!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Button
						className="bg-appColorBlue2"
            type="primary"
            htmlType="submit"
            block
            loading={loading}
            disabled={loading}
          >
            Request
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default StripeAccountLinkingRefresh;
