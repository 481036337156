import axios from 'axios';
import { appDetails } from '../utils/appdetails';

/**
 * Axios instance with auth
 */
export const http = axios.create({
  baseURL: appDetails.BASE_API_URL
  // timeout: 5000,
  // headers: {
  //   "Content-type": "application/json",
  //   "Access-Control-Allow-Origin": "*"
  // }
});

/**
 * Axios instance without auth
 */
export const httpNoAuth = axios.create({
  baseURL: appDetails.BASE_API_URL
  // timeout: 5000,
  // headers: {
  //   "Content-type": "application/json",
  //   "Access-Control-Allow-Origin": "*"
  // }
});

/**
 * Set token headers
 * @param token string
 */
export const setAuthToken = (token: string | null) => {
  if (token !== null) {
    http.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    delete http.defaults.headers.common.Authorization;
  }
};
