import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  loginUserInterface,
  updateUserInterface,
  UserState,
} from "./userSlice-interface";
import { setAuthToken } from "../../api";

const initialState: UserState = {
  user: null,
  token: null,
  isAuthenticated: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginUser: (state, action: PayloadAction<loginUserInterface>) => {
      state.user = action.payload.user;
      state.isAuthenticated = true;
      state.token = action.payload.token;
      setAuthToken(action.payload.token);
    },
    logoutUser: (state) => {
      state.user = null;
      state.isAuthenticated = false;
      state.token = null;
      setAuthToken(null);
    },
    updateUser: (state, action: PayloadAction<updateUserInterface>) => {
      state.user = action.payload.user;
    },
  },
});

export const { loginUser, logoutUser, updateUser } = userSlice.actions;

const userReducer = userSlice.reducer;

export default userReducer;
